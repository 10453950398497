import React from "react";

const Stats = () => {
  return (
    <div>
      <h1 class="text-3xl">Hey guys we are having a baby :D</h1>
      <img src="IMG_6758.jpg" alt="baby" class="mt-12 w-1/3"></img>
    </div>
  );
};

export default Stats;

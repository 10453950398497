import React from "react";
import Header from "../Components/Header";
import MainPage from "../Components/MainPage";
import Footer from "../Components/Footer";

const Home = () => {
  return (
    <div>
      <MainPage />
    </div>
  );
};

export default Home;
